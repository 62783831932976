import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/scrollbar.css';

//fr
import HeaderFr from './fr/HeaderFr';
import IndexFr from './fr/pages/Index';
import FooterFr from './fr/FooterFr';
import ProjetsFr from './fr/pages/Projets';
import AProposFr from './fr/pages/APropos';
import ContactFr from './fr/pages/Contact';
import Validation from './fr/pages/Validation';

import ScrollToTheTop from './components/ScrollToTheTop';

//en
import HeaderEn from './en/HeaderEn';
import FooterEn from './en/FooterEn';
import IndexEn from './en/pages/IndexEn';
import Projects from './en/pages/Projects';
import About from './en/pages/About';
import ContactEn from './en/pages/ContactEn';
import ValidationEn from './en/pages/ValidationEn';

import reportWebVitals from './reportWebVitals';
import Lang from './Lang';

const App = () => {
  //state
  const [currentPage, setCurrentPage] = useState('accueil');
  const [langage, setLangage] = useState('fr');

  // handlePageChange function
  const handlePageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  // handleLangageChange function
  const handleLangageChange = (l) => {
    setLangage(l);
  };

  //render
  return (
    <React.StrictMode>
      {currentPage !== 'accueil' && <ScrollToTheTop />}
      {currentPage !== 'validation' && <Lang onLangageChange={handleLangageChange} langage={langage}/>}
      {currentPage !== 'validation' && langage === 'fr' && <HeaderFr onPageChange={handlePageChange} />}
      {currentPage !== 'validation' && langage === 'en' && <HeaderEn onPageChange={handlePageChange} />}
      
      {currentPage === 'accueil' && langage === 'fr' && <IndexFr />}
      {currentPage === 'projets' && langage === 'fr' && <ProjetsFr />}
      {currentPage === 'a-propos' && langage === 'fr' && <AProposFr />}
      {currentPage === 'contact' && langage === 'fr' && <ContactFr onPageChange={handlePageChange} />}

      {currentPage === 'accueil' && langage === 'en' && <IndexEn />}
      {currentPage === 'projets' && langage === 'en' && <Projects />}
      {currentPage === 'a-propos' && langage === 'en' && <About />}
      {currentPage === 'contact' && langage === 'en' && <ContactEn onPageChange={handlePageChange} />}

      {currentPage !== 'validation' && langage === 'fr' && <FooterFr onPageChange={handlePageChange} />}
      {currentPage !== 'validation' && langage === 'en' && <FooterEn onPageChange={handlePageChange} />}

      {currentPage === 'validation' && langage === 'fr' && <Validation onPageChange={handlePageChange} />}
      {currentPage === 'validation' && langage === 'en' && <ValidationEn onPageChange={handlePageChange} />}
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();